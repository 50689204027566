/* eslint-disable no-undef */
// https://www.researchgate.net/figure/Supported-standard-aspect-ratios-of-a-digital-image-grouped-by-image-orientation_tbl4_341431285

export const sizePressets = [
  {
    id: 'size-custom',
    name: 'Custom',
    width: NaN,
    height: NaN,
    aspectRatioRef: 'aspect-ratio-custom',
  },
  {
    id: 'size-480x480',
    name: '480x480',
    width: 480,
    height: 480,
    aspectRatioRef: 'aspect-ratio-11',
  },
  {
    id: 'size-512x512',
    name: '512x512',
    width: 512,
    height: 512,
    aspectRatioRef: 'aspect-ratio-11',
  },
  {
    id: 'size-1024x1024',
    name: '1024x1024',
    width: 1024,
    height: 1024,
    aspectRatioRef: 'aspect-ratio-11',
  },
  {
    id: 'size-640x480',
    name: '640x480',
    width: 640,
    height: 480,
    aspectRatioRef: 'aspect-ratio-43',
  },
  {
    id: 'size-800x600',
    name: '800x600',
    width: 800,
    height: 600,
    aspectRatioRef: 'aspect-ratio-43',
  },
  {
    id: 'size-832x624',
    name: '832x624',
    width: 832,
    height: 624,
    aspectRatioRef: 'aspect-ratio-43',
  },
  {
    id: 'size-600x480',
    name: '600x480',
    width: 600,
    height: 480,
    aspectRatioRef: 'aspect-ratio-54',
  },
  {
    id: 'size-1280x1024',
    name: '1280x1024',
    width: 1280,
    height: 1024,
    aspectRatioRef: 'aspect-ratio-54',
  },
  {
    id: 'size-1600x1280',
    name: '1600x1280',
    width: 1600,
    height: 1280,
    aspectRatioRef: 'aspect-ratio-54',
  },
  {
    id: 'size-960x640',
    name: '960x640',
    width: 960,
    height: 640,
    aspectRatioRef: 'aspect-ratio-32',
  },
  {
    id: 'size-1152x768',
    name: '1152x768',
    width: 1152,
    height: 768,
    aspectRatioRef: 'aspect-ratio-32',
  },
  {
    id: 'size-1440x960',
    name: '1440x960',
    width: 1440,
    height: 960,
    aspectRatioRef: 'aspect-ratio-32',
  },

  {
    id: 'size-800x480',
    name: '800x480',
    width: 800,
    height: 480,
    aspectRatioRef: 'aspect-ratio-53',
  },
  {
    id: 'size-1280x768',
    name: '1280x768',
    width: 1280,
    height: 768,
    aspectRatioRef: 'aspect-ratio-53',
  },

  {
    id: 'size-960x540',
    name: '960x540',
    width: 960,
    height: 540,
    aspectRatioRef: 'aspect-ratio-169',
  },
  {
    id: 'size-1024x576',
    name: '1024x576',
    width: 1024,
    height: 576,
    aspectRatioRef: 'aspect-ratio-169',
  },
  {
    id: 'size-1280x720',
    name: '1280x720',
    width: 1280,
    height: 720,
    aspectRatioRef: 'aspect-ratio-169',
  },

  {
    id: 'size-1200x400',
    name: '1200x400',
    width: 1200,
    height: 400,
    aspectRatioRef: 'aspect-ratio-31',
  },
  {
    id: 'size-1500x500',
    name: '1500x500',
    width: 1500,
    height: 500,
    aspectRatioRef: 'aspect-ratio-31',
  },
  {
    id: 'size-1800x600',
    name: '1800x600',
    width: 1800,
    height: 600,
    aspectRatioRef: 'aspect-ratio-31',
  },

  {
    id: 'size-700x2100',
    name: '700x2100',
    width: 700,
    height: 2100,
    aspectRatioRef: 'aspect-ratio-13',
  },
  {
    id: 'size-800x2400',
    name: '800x2400',
    width: 800,
    height: 2400,
    aspectRatioRef: 'aspect-ratio-13',
  },
  {
    id: 'size-900x2700',
    name: '900x2700',
    width: 900,
    height: 2700,
    aspectRatioRef: 'aspect-ratio-13',
  },

  {
    id: 'size-720x960',
    name: '720x960',
    width: 720,
    height: 960,
    aspectRatioRef: 'aspect-ratio-34',
  },
  {
    id: 'size-768x1024',
    name: '768x1024',
    width: 768,
    height: 1024,
    aspectRatioRef: 'aspect-ratio-34',
  },
  {
    id: 'size-864x1152',
    name: '864x1152',
    width: 864,
    height: 1152,
    aspectRatioRef: 'aspect-ratio-34',
  },

  {
    id: 'size-480x800',
    name: '480x800',
    width: 480,
    height: 800,
    aspectRatioRef: 'aspect-ratio-35',
  },
  {
    id: 'size-768x1280',
    name: '768x1280',
    width: 768,
    height: 1280,
    aspectRatioRef: 'aspect-ratio-35',
  },

  {
    id: 'size-1280x1600',
    name: '1280x1600',
    width: 1280,
    height: 1600,
    aspectRatioRef: 'aspect-ratio-45',
  },
  {
    id: 'size-1440x1800',
    name: '1440x1800',
    width: 1440,
    height: 1800,
    aspectRatioRef: 'aspect-ratio-45',
  },
  {
    id: 'size-2048x2560',
    name: '2048x2560',
    width: 2048,
    height: 2560,
    aspectRatioRef: 'aspect-ratio-45',
  },

  {
    id: 'size-900x1920',
    name: '900x1920',
    width: 900,
    height: 1920,
    aspectRatioRef: 'aspect-ratio-916',
  },
  {
    id: 'size-1080x1920',
    name: '1080x1920',
    width: 1080,
    height: 1920,
    aspectRatioRef: 'aspect-ratio-916',
  },
  {
    id: 'size-1440x2560',
    name: '1440x2560',
    width: 1440,
    height: 2560,
    aspectRatioRef: 'aspect-ratio-916',
  },

  {
    id: 'size-1280x1920',
    name: '1280x1920',
    width: 1280,
    height: 1920,
    aspectRatioRef: 'aspect-ratio-23',
  },
  {
    id: 'size-1440x2160',
    name: '1440x2160',
    width: 1440,
    height: 2160,
    aspectRatioRef: 'aspect-ratio-23',
  },
  {
    id: 'size-1824x2736',
    name: '1824x2736',
    width: 1824,
    height: 2736,
    aspectRatioRef: 'aspect-ratio-23',
  },
  {
    id: 'size-400x40-gnews',
    name: 'Google News - Rectangular Logo',
    width: 400,
    height: 40,
    aspectRatioRef: 'aspect-ratio-gnews-rectangularlogo',
  },
];
