/* eslint-disable no-undef */

export const imageFormats = [
  {
    value: 'rj',
    name: 'JPEG',
  },
  {
    value: 'rp',
    name: 'PNG',
  },
  {
    value: 'rw',
    name: 'WebP',
  },
];
